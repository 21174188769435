import {useContext, useEffect, useState} from "react";
import {logout} from "../../utils/router";
import {useRouter} from 'next/router'
import styles from "./header.module.scss";

import {hideHeaderFooter} from "../../utils/utils";
import Link from "next/link";
import { UserContext } from '../providers/UserProvider'
import { ProductContext } from "../providers/ProductProvider";

const Header = () => {
    const router = useRouter();
    const {pathname} = router;
    const { user, setUserContextValue} = useContext(UserContext);
    const { setProductContextValue } = useContext(ProductContext);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        setHidden(hideHeaderFooter(pathname));
    }, [pathname]);

    const handleLinkClick = () => {
        setDropdownOpen(false);
    };

    const clearStore = () => {
        localStorage.removeItem('isPromotional')
        setProductContextValue(ctx => ({
            ...ctx,
            isPromotional: false,
            selectedDrug: null,
            selectedStrength: null,
            selectedPills: null
        }));
    }

    const handleLogout = async () => {
        if (!user) return;
        try {
            await logout();
        } catch (err) {
            // Already handled in router
        } finally {
            setUserContextValue((ctx) => ({...ctx, user: null}));
            await router.push("/");
        }
    };

    return (
        <>
            <div className={styles.header}>
                <div className={`${styles.header_container} ${hidden ? styles.header_hidden : ''}`}>
                    <Link href="/">
                        <a className={styles.header_logo} target={hidden ? '_blank' : '_self'} onClick={clearStore}>
                            <img
                                src="/assets/companyLogo.svg"
                                alt={`Brand Logo`}
                                className={styles.header_logo_img}
                            />
                        </a>
                    </Link>
                    {!hidden && (
                        <div className={styles.header_mobile_container}>
                            <Link passHref href="/">
                                <a className={styles.header_logo_mobile} target={hidden ? '_blank' : '_self'} onClick={clearStore}>
                                    <img
                                        src="/assets/companyLogo.svg"
                                        alt={`Brand Logo`}
                                        className={styles.header_logo_img}
                                    />
                                </a>
                            </Link>
                            <div className={styles.header_burger} onClick={() => setDropdownOpen(!dropdownOpen)}>
                                <span className={`${dropdownOpen ? styles.active : ''}`}/>
                            </div>
                        </div>
                    )}
                    {!hidden ? (
                        <>
                            <div
                                onClick={handleLinkClick}
                                className={`${styles.header_menu} ${dropdownOpen ? styles.is_active : ''}`}
                            >
                                <Link passHref href="/products/erectile-disfunction">
                                    <a className={styles.header_menu_link}>
                                        Erectile Dysfunction
                                    </a>
                                </Link>
                                <Link passHref href="/products/premature-ejaculation">
                                    <a className={styles.header_menu_link}>
                                        Premature Ejaculation
                                    </a>
                                </Link>
                                {user ? (
                                    <Link passHref href="/account">
                                        <a className={styles.header_menu_link}>
                                            Account
                                        </a>
                                    </Link>
                                ) : (
                                    <Link passHref href="/login">
                                        <a className={styles.header_menu_link}>
                                            Login
                                        </a>
                                    </Link>
                                )}
                                <div
                                    className={styles.header_menu_button}
                                >
                                    {user ? (
                                        <span className="cursor-p" onClick={handleLogout}>Logout</span>
                                    ) : (
                                        <Link passHref href="/questionnaire/cta">
                                            <a className="btn-secondary">Get Started</a>
                                        </Link>
                                    )}
                                </div>
                            </div>
                            <div className={styles.header_button}>
                                {user ? (
                                    <span className="cursor-p" onClick={handleLogout}>Logout</span>
                                ) : (
                                    <Link passHref href="/questionnaire/cta">
                                        <a className="btn-secondary">Get Started</a>
                                    </Link>
                                )}
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </>
    );
};

export default Header;
