import jwt_decode from "jwt-decode";

export const EDProductType = 0;
export const PEProductType = 1;
export const BundleProductType = 2;

export const productTypes = {
    ed: EDProductType,
    pe: PEProductType,
    "erectile-disfunction": EDProductType,
    "premature-ejaculation": PEProductType,
    "bundle": BundleProductType
};

export const productTypesURLS = {
    0: "erectile-disfunction",
    1: "premature-ejaculation",
    2: "bundle"
};

export const bundlePlans = [
    {
        _id: ['626c3e2df0847c467a1c0797', '626c3b49f0847c467a1c0722'],
        backendVisitType: 'bundle',
        bottleIcon: '/pills/bundle.png',
        company: 'upForLove',
        drugName: 'Sertraline 50 MG + Tadalafil 5 MG',
        drug: 'bundle',
        icon: '/pills/bundle.png',
        isPromotion: false,
        longDescription: '',
        months: 1,
        pills: 31,
        planName: 'Sertraline/50MG/Tadalafil/5MG',
        price: 189,
        refills: '',
        shortDescription: 'Sertraline + Tadalafil',
        strength: 55,
        customStrength: 'Sertraline 50 mg, Tadalafil 5mg',
        subTitle: 'ED+PE Combo Offer',
        totalPills: 31,
        type: 2,
        visitType: 'bundle'
    },
    {
        _id: ['626c3e43f0847c467a1c079c', '626c3b62f0847c467a1c0727'],
        backendVisitType: 'bundle',
        bottleIcon: '/pills/bundle.png',
        company: 'upForLove',
        drugName: 'Sertraline 50 MG + Tadalafil 5 MG',
        drug: 'bundle',
        icon: '/pills/bundle.png',
        isPromotion: false,
        longDescription: '',
        months: 3,
        pills: 31,
        planName: 'Sertraline/50MG/Tadalafil/5MG',
        price: 389,
        refills: '',
        shortDescription: 'Sertraline + Tadalafil',
        customStrength: 'Sertraline 50 mg, Tadalafil 5mg',
        strength: 55,
        subTitle: 'ED+PE Combo Offer',
        totalPills: 93,
        type: 2,
        visitType: 'bundle'
    },
    {
        _id: ['626c3e5bf0847c467a1c07a1', '626c3b87f0847c467a1c072c'],
        backendVisitType: 'bundle',
        bottleIcon: '/pills/bundle.png',
        company: 'upForLove',
        drugName: 'Sertraline 50 MG + Tadalafil 5 MG',
        drug: "bundle",
        icon: '/pills/bundle.png',
        isPromotion: false,
        longDescription: '',
        months: 6,
        pills: 31,
        planName: 'Sertraline/50MG/Tadalafil/5MG',
        price: 659,
        refills: '',
        shortDescription: '',
        customStrength: 'Sertraline 50 mg, Tadalafil 5mg',
        strength: 55,
        subTitle: 'ED+PE Combo Offer',
        totalPills: 186,
        type: 2,
        visitType: 'bundle'
    }
]

export const formatProductObject = (p) => {
    const pillsPerMonth = Math.floor(parseInt(p.totalPills) / parseInt(p.months));
    const pillsByType = p.drug === 'Sertraline' ? 31 : 30
    const backendVisitType = p.visitType,
          visitType = p.visitType === "ED" ? "erectile-disfunction" : "premature-ejaculation";
    return {
        ...p,
        visitType,
        backendVisitType,
        type: productTypes[visitType],
        icon: `/pills/${p.drug}-supplement.png`,
        bottleIcon: `/pills/${p.drug}.png`,
        price: parseFloat(p.price),
        strength: parseInt(p.strength),
        months: parseInt(p.months),
        totalPills: parseInt(p.totalPills),
        refills: parseInt(p.refills),
        pills: pillsPerMonth >= 29 ? pillsByType : pillsPerMonth,
    };
};

export const formatTimestamp = (timestamp) =>
    new Date(timestamp).toLocaleDateString("en-US");

export function getLocalToken() {
    if (typeof window === "undefined") return null
    return localStorage.getItem("jwt") || sessionStorage.getItem("jwt");
}

const getJWTContent = () => {
    try {
        const token = getLocalToken();
        return jwt_decode(token);
    } catch (_) {
        return {};
    }
};

export const getJWTField = (field) => getJWTContent()[field];

export const hideHeaderFooter = (pathname) => {
    // const hidePatterns = [/\/questionnaire/, /^\/products\/[\w-]+\/?(?:plan|checkout|\d+\/\d+\/\d+)?$/];
    const hidePatterns = [/\/questionnaire/, /\/recommendation/, /\/checkout/, /\/ambassadors-program/, /\/reset-password/, /\/forgot-password/, /\/unsubscribe/];
    return hidePatterns.some((pattern) => pathname.match(pattern));
};

export const hideZendesk = pathname => {
    const hidePatterns = [/\/account/, /\/terms/, /\/privacy/];
    return pathname === '/' || hidePatterns.some((pattern) => pathname.match(pattern));
}

export const emptyPage = (pathname) => {
    const hidePatterns = [/\/ambassadors-program/];
    return hidePatterns.some((pattern) => pathname.match(pattern));
}

function getWithExpiry(key) {
	const itemStr = localStorage.getItem(key)
	// if the item doesn't exist, return null
	if (!itemStr) {
		return null
	}
	const item = JSON.parse(itemStr)
	const now = new Date()
	// compare the expiry time of the item with the current time
	if (now.getTime() > item.expiry) {
		// If the item is expired, delete the item from storage
		// and return null
		localStorage.removeItem(key)
		return null
	}
	return item.value
}

function setWithExpiry(key, value, ttl) {
	const now = new Date()

	// `item` is an object which contains the original value
	// as well as the time when it's supposed to expire
	const item = {
		value: value,
		expiry: now.getTime() + ttl,
	}
	localStorage.setItem(key, JSON.stringify(item))
}


export const localStorageProvider = () => {
    if (typeof window === "undefined")  // Pre-rendering
        return new Map()

    // When initializing, we restore the data from `localStorage` into a map.
    const map = new Map(JSON.parse(getWithExpiry('app-cache') || '[]'))

    // Before unloading the app, we write back all the data into `localStorage`.
    window.addEventListener('beforeunload', () => {
        const appCache = JSON.stringify(Array.from(map.entries()))
        setWithExpiry('app-cache', appCache, 1000 * 60 * 60) // 1 hour cache
    })

    // We still use the map for write & read for performance.
    return map
}

export const checkEmail = email => {
    const result = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return result.test(email);
}

export const checkPassword = password => {
    const result = /^[a-z\d~`!@#%&_=:;"'<,>\$\^\*\(\)\-\+\{\}\[\]\|\\\.\?\/]{8,64}$/gi

    return result.test(password)
}
