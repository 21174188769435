import { API } from 'aws-amplify'
import { formatProductObject, getLocalToken } from './utils'

export const apiKey = process.env.NEXT_PUBLIC_API_KEY,
    company = process.env.NEXT_PUBLIC_COMPANY;

export const login = async (loginData) => {
    try {
        const res = await API.post("brains", "/auth/customerLogin", {
            credentials: "include",
            body: { company, apiKey, ...loginData },
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (res.status !== 200 || res.info !== "login successful")
            throw Error(res.info);
        localStorage.setItem("jwt", res.token);
        sessionStorage.setItem("jwt", res.token);
        return res.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const logout = async () => {
    const localToken =
        localStorage.getItem("jwt") || sessionStorage.getItem("jwt");
    if (!localToken) return;
    try {
        const res = await API.post("brains", "/auth/customerLogout", {
            credentials: "include",
            body: {
                company,
            },
            headers: {
                Authorization: `Bearer ${localToken}`,
            },
        });
        if (res.status !== 200 || res.data !== "logout successful")
            throw Error(res.info);
        localStorage.removeItem("jwt");
        sessionStorage.removeItem("jwt");
        return res.data;
    } catch (err) {
        console.error(err);
        return err;
    }
};

export const changePassword = async (
    user,
    currentPassword,
    newPassword
) => {
    const localToken = getLocalToken();
    try {
        const passwordChange = {
            currentPassword: currentPassword,
            password: newPassword,
            company,
        };
        const response = await API.post(
            "brains",
            `/patient/changePassword/${user._id}`,
            {
                credentials: "include",
                body: passwordChange,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localToken}`,
                },
            }
        );
        if (response.status !== 200)
            throw Error(response.error);

        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const forgotPassword = async (email) => {
    try {
        const res = await API.post("brains", "/patient/forgotPassword", {
            credentials: "include",
            body: { company, apiKey, email },
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (res.status !== 200 || res.data !== "Request received")
            throw Error(res.data);

    } catch (err) {
        console.error(err);
        throw err;
    }
}

export const forgotPasswordCheck = async (resetId) => {
    try {
        const res = await API.post("brains", "/patient/forgotPasswordCheck", {
            credentials: "include",
            body: { company, apiKey, resetId },
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (res.status !== 200 || res.data !== "Link is valid")
            throw Error(res.data);

    } catch (err) {
        console.error(err);
        throw err;
    }
}

export const resetPassword = async (resetId, password) => {
    try {
        const res = await API.post("brains", "/patient/resetPassword", {
            credentials: "include",
            body: { company, apiKey, resetId, password },
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (res.status !== 200 || res.data !== "Link is valid")
            throw Error(res.data);

    } catch (err) {
        console.error(err);
        throw err;
    }
}

export const getOrders = async (user, company) => {
    const localToken = getLocalToken();

    try {
        const body = {
            company,
        };
        const orderResponse = await API.post(
            "brains",
            `/order/filtered/${user._id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localToken}`,
                },
                body,
            }
        );
        if (orderResponse.status !== 200) throw Error("Fetching orders failed");

        return orderResponse.data;
    } catch (err) {
        console.error("error in getOrders", err);
        throw err;
    }
};

export const getStates = async () => {
    try {
        const body = {
            apiKey,
        };
        const response = await API.post("brains", "/state/allStates", {
            headers: {
                "Content-Type": "application/json",
            },
            body,
        });
        return response.data;
    } catch (err) {
        console.log("err in getStates", err);
    }
};

export const sendOrder = async (body) => {
    const localToken = getLocalToken()
    const visitCreationResponse = await API.post("brains", "/visit/checkout", {
        credentials: "include",
        body: {...body, apiKey, company},
        headers: {
            "Content-Type": "application/json",
            ...(localToken && {Authorization: `Bearer ${localToken}`}),
        },
    });
    if (visitCreationResponse.status !== 200) {
        switch (visitCreationResponse.errorType) {
            case "payment":
                throw Error(
                    "Something went wrong with your credit card transaction, please check your payment info and try again."
                );
            case "state":
                throw Error(
                    "Sorry, async visits are not allowed in your state. We will let you know when this becomes available in your state."
                );
            case "other":
            default:
                throw Error(
                    visitCreationResponse.error ?? "Something went wrong, please check all your info and try again."
                );
        }
    }
    return visitCreationResponse.data;

};

export const tokenLogin = async () => {
    const localToken = getLocalToken();

    const response = await API.post("brains", "/auth/customerLoggedIn", {
        credentials: "include",
        body: {
            company,
        },
        headers: {
            Authorization: `Bearer ${localToken}`,
        },
    });
    if (response.name && response.name === "TokenExpiredError")
        throw Error("Expired token");

    return response.data;
};

export const checkPhoneValid = async (phone) => {
    const phoneResponse = await API.post("brains", "/external/phoneLookup", {
        credentials: "include",
        body: {
            phone,
            apiKey,
        },
        headers: {
            "Content-Type": "application/json",
        },
    });
    if (phoneResponse.status !== 200)
        throw Error("An error occured while checking the phone number");

    return phoneResponse.data;
};

export const fetchProductList = async () => {
    try {
        const res = await API.post("brains", "/product/allProducts", {
            headers: {
                "Content-Type": "application/json",
            },
            body: {apiKey, company},
        });
        if (res.status !== 200) throw Error("Backend error while listing products", res);

        return res.data.map(formatProductObject)
    } catch (err) {
        console.error("Error occured while fetching products", err);
        throw err
    }
};

export const fetchShippingOptions = async () => {
    try {
        const res = await API.post("brains", "/order/shippingOptions", {
            headers: {
                "Content-Type": "application/json",
            },
            body: {apiKey, company},
        });
        if (res.status !== 200) throw Error("Backend error while listing shipping methods", res);

        return res.data
    } catch (err) {
        console.error("Error occured while fetching shipping methods", err);
        throw err
    }
};

const submitPhoto = async (apiKey, visitId, photoObject) => {
    const body = {
        visitId: visitId,
        image: {
            mime: photoObject.type,
            data: photoObject.photo,
        },
        apiKey,
    };
    try {
        const photoResponse = await API.post("brains", "/external/receivePhoto", {
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body,
        });
        if (photoResponse.status !== 200)
            throw Error("Something went wrong, please upload a photo and try again.");

        return photoResponse;
    } catch (err) {
        console.log("err", err);
        throw Error("Something went wrong, please upload a photo and try again.");
    }
};

export const updateVisit = async (
    formData,
    patientId,
    orderId,
    visitId,
    photoObject
) => {
    const {consentsSigned, allergies, medicalConditions, selfReportedMeds} =
        formData;
    let stringifiedAnswers = formData;
    stringifiedAnswers = Object.keys(stringifiedAnswers).forEach((k) => {
        if (Array.isArray(stringifiedAnswers[k])) {
            stringifiedAnswers[k] = stringifiedAnswers[k].join(";");
        }
    });
    const body = {
        visitUpdate: {
            formObj: {
                consentsSigned,
                allergies: allergies ? allergies : "None",
                medicalConditions: medicalConditions ? medicalConditions : "None",
                selfReportedMeds: selfReportedMeds ? selfReportedMeds : "None",
                ...formData,
            },
        },
        patientId,
        orderId,
        apiKey,
        company,
    };
    try {
        const visitCreationResponse = await API.post(
            "brains",
            `/visit/updateFormData/${visitId}`,
            {
                credentials: "include",
                body,
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        if (visitCreationResponse.status !== 200) {
            switch (visitCreationResponse.errorType) {
                case "other":
                default:
                    throw Error(
                        "Something went wrong, please check all your info and try again."
                    );
            }
        }
    } catch (err) {
        console.log("visit creation error", err);
        throw Error(
            "Something went wrong, please check all your info and try again."
        );
    }
    return await submitPhoto(apiKey, visitId, photoObject);
};

export const checkExistingAccount = async (email, phone) => {
    const accountResponse = await API.post("brains", "/patient/contactInfoCheck", {
        credentials: "include",
        body: {
            apiKey,
            company,
            email,
            phone,
        },
        headers: {
            "Content-Type": "application/json",
        },
    });
    if (accountResponse.status !== 200)
        throw Error("An error occured while checking existing account");

    return accountResponse;
};

export const getTaxRate = async (zip) => {
    return {rate: "0.08"}; // While we are not collecting tax, we will set it to 8%
    const taxResponse = await API.post("brains", "/order/taxCalc", {
        credentials: "include",
        body: {
            apiKey,
            company,
            zip
        },
        headers: {
            "Content-Type": "application/json",
        },
    });
    if (taxResponse.status !== 200)
        throw Error("An error occured while checking existing account");

    return taxResponse.data;
};


export const flagUserIneligible = async (orderId) => {
    const localToken =
        localStorage.getItem("jwt") || sessionStorage.getItem("jwt");
    const orderResponse = await API.post("brains", "/order/ineligible", {
        credentials: "include",
        body: {
            company,
            orderId
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localToken}`,
        },
    });
    if (orderResponse.status !== 200)
        throw Error("An error occured while updating order/account");

    return orderResponse;
};

export const cancelOrder = async (orderId) => {
    const localToken =
        localStorage.getItem("jwt") || sessionStorage.getItem("jwt");
    const orderResponse = await API.post("brains", "/order/customerCancel", {
        credentials: "include",
        body: {
            company,
            orderId
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localToken}`,
        },
    });
    if (orderResponse.status !== 200)
        throw Error("An error occured while updating order/account");

    return orderResponse.data;
};

export const subscribeEmail = async (email) => {
    const emailResponse = await API.post("brains", "/external/newsletterSubscribe", {
        credentials: "include",
        body: {
            apiKey,
            email
        },
        headers: {
            "Content-Type": "application/json",
        },
    });
    if (emailResponse.status !== 200)
        throw Error("An error occured while registering email");

    return emailResponse;
};

export const unsubscribeEmail = async (email) => {
    const emailResponse = await API.post("brains", "/external/newsletterUnsubscribe", {
        credentials: "include",
        body: {
            apiKey,
            email
        },
        headers: {
            "Content-Type": "application/json",
        },
    });
    if (emailResponse.status !== 200)
        throw Error("An error occured while registering email");

    return emailResponse;
}

export const getCouponCode = async (coupon) => {
    const orderResponse = await API.post("brains", "/order/couponCheck", {
        credentials: "include",
        body: {
            apiKey,
            coupon
        },
        headers: {
            "Content-Type": "application/json",
        },
    });
    if (orderResponse.status !== 200)
        return null

    return orderResponse.data;
};

export const SWRFetcher = async (url) => {
    try {
        const localToken = getLocalToken();
        const response = await API.post("brains", url, {
            headers: {
                "Content-Type": "application/json",
                ...(localToken && {Authorization: `Bearer ${localToken}`}),
            },
            body: {apiKey, company},
        });
        return response.data;
    } catch (err) {
        console.error("Error occured during SWR request");
        throw err
    }
}
