import React, {useEffect, useState} from 'react'
import Modal from 'react-modal'
import Head from 'next/head'
import './app.scss'
import 'react-toastify/dist/ReactToastify.css'
import {ToastContainer} from 'react-toastify'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import UserProvider from '../components/providers/UserProvider'
import config from "../utils/config";
import { Amplify, API, Auth } from 'aws-amplify'
import ProductProvider from '../components/providers/ProductProvider'
import { SWRConfig } from 'swr'
import { SWRFetcher } from '../utils/router'
import { hideZendesk } from "../utils/utils";
import { localStorageProvider, emptyPage } from '../utils/utils'
import { useRouter } from "next/router";
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

Amplify.configure(config);
Auth.configure(config);
API.configure(config);

if (process.env.NEXT_PUBLIC_STAGE === "prod")
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: "prod",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });

const App = ({Component, pageProps}) => {
    const getLayout = Component.getLayout || ((page) => page)
    const router = useRouter();
    const { pathname } = router;

    const [isEmptyPage, setIsEmptyPage] = useState(false)
    const [isZendeskScriptExist, setIsZendeskScriptExist] = useState(false)

    useEffect(() => {
        setIsEmptyPage(emptyPage(pathname))

        // ------------- ZENDESK -----------------
        // if(hideZendesk(pathname) && !isZendeskScriptExist) {
        //     const scriptTag = document.createElement("script");
        //     scriptTag.setAttribute("id", "ze-snippet");
        //     scriptTag.setAttribute("src", "https://static.zdassets.com/ekr/snippet.js?key=39a5db5b-2f0a-45f4-be1a-df96bef76401");
        //     document.getElementsByTagName("head")[0].appendChild(scriptTag);
        //     setIsZendeskScriptExist(true)
        // } else {
        //     const widget = document.querySelector('iframe[style="height: 64px; width: 64px; position: fixed; bottom: 16px; right: 16px; transform: none; border: 0px; margin-top: 0px; box-shadow: rgba(36, 36, 36, 0.1) 0px 8px 24px 0px, rgba(71, 69, 123, 0.04) 0px 1px 4px 0px; animation: 0.2s ease-in 0s 1 normal none running webSDKOnLoad, 0s ease 0s 1 normal none running undefined; z-index: 999999; border-radius: 50%;"]')
        //     if (widget) widget.parentElement.style.display = hideZendesk(pathname) ? "block" : "none"
        // }
    }, [pathname])

    Modal.setAppElement('#root')

    return (
      <SWRConfig value={{
        fetcher: SWRFetcher,
        provider: localStorageProvider
      }}>
        <UserProvider>
          <ProductProvider>
            <Head>
              {/*https://nextjs.org/docs/messages/no-document-viewport-meta*/}
              <meta key="viewport" name="viewport" content="width=device-width, initial-scale=1"/>
              <meta key="og:title" property="og:title"
                    content="upfor.love | Erectile Dysfunction and Premature Ejaculation Treatments Discreet & 100% Online"/>
              <meta key="twitter:title" property="twitter:title"
                    content="upfor.love | Erectile Dysfunction and Premature Ejaculation Treatments Discreet & 100% Online"/>
              <meta key="description" name="description"
                    content="upfor.love is a men’s sexual health brand dedicated to helping men take control of their sexual well-being. We connect men with US Board-Certified Physicians to ensure they receive the treatment they need quickly, easily, and discreetly."/>
              <meta key="twitter:description" property="twitter:description"
                    content="upfor.love is a men’s sexual health brand dedicated to helping men take control of their sexual well-being. We connect men with US Board-Certified Physicians to ensure they receive the treatment they need quickly, easily, and discreetly."/>
              <meta key="og:description" property="og:description"
                    content="upfor.love is a men’s sexual health brand dedicated to helping men take control of their sexual well-being. We connect men with US Board-Certified Physicians to ensure they receive the treatment they need quickly, easily, and discreetly."/>
              <meta key="image" name="image" content="/assets/spaceman.png"/>
              <meta key="og:image" property="og:image" content="/assets/spaceman.png"/>
              <meta key="twitter:image" property="twitter:image" content="/assets/spaceman.png"/>
              <title key="title">upfor.love | Erectile Dysfunction and Premature Ejaculation Treatments Discreet & 100% Online</title>
            </Head>
            <div className="App">
                <div className="App_content">
                    {!isEmptyPage && <Header/>}
                    <div className={`main_page_container${isEmptyPage ? ' hidden' : ''}`}>
                    {getLayout(<Component {...pageProps} />)}
                    </div>
                    {!isEmptyPage && <Footer/>}
                    <ToastContainer/>
                </div>
            </div>
          </ProductProvider>
        </UserProvider>
      </SWRConfig>
    )
}

export default App
