const dev = {
    ssr: true,
    API: {
        endpoints: [
            {
                name: "brains",
                region: "us-east-1",
                endpoint: "https://api-staging.belugahealth.com",
                // URL: "http://localhost:9000"
            },
        ],
    },
};

const prod = {
    ssr: true,
    API: {
        endpoints: [
            {
                name: "brains",
                region: "us-east-1",
                endpoint: "https://api.belugahealth.com",
            },
        ],
    },
};

const config = process.env.NEXT_PUBLIC_STAGE === "prod" ? prod : dev;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    ...config,
};
