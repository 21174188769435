import { useRouter } from "next/router";
import React, { createContext, useEffect, useState } from "react";
import { company, tokenLogin } from '../../utils/router'
import { formatProductObject, getLocalToken } from '../../utils/utils'
import useSWRImmutable from 'swr/immutable'
import { useSWRConfig } from 'swr'


const defaultUserContextValue = {
    user: null, isCheckingLogin: true, orders: [], setUserContextValue: () => {},
};

export const UserContext = createContext(defaultUserContextValue);

const UserProvider = ({ children }) => {
    const [contextValue, setContextValue] = useState(defaultUserContextValue);
    const { data: orders } = useSWRImmutable(() => `/order/filtered/${contextValue.user._id}`, undefined)
    const router = useRouter();
    const { mutate } = useSWRConfig()
    const localToken = getLocalToken()

    const checkLogin = async () => {
        try {
            if (getLocalToken()) {
                setContextValue((ctx) => ({
                    ...ctx, isCheckingLogin: true,
                }));
                const data = await tokenLogin();
                setContextValue((ctx) => ({
                    ...ctx, isCheckingLogin: false, user: data,
                }));
                await mutate(`/order/filtered/${data._id}`)
            } else setContextValue((ctx) => ({
                ...ctx, isCheckingLogin: false,
            }));
        } catch (err) {
            console.error(err);
            setContextValue((ctx) => ({
                ...ctx, isCheckingLogin: false, user: null,
            }));
            localStorage.removeItem("jwt");
            sessionStorage.removeItem("jwt");
            await router.push("/");
        }
    };

    useEffect(() => {
        setContextValue((ctx) => ({
            ...ctx, setUserContextValue: setContextValue,
        }));
        // noinspection JSIgnoredPromiseFromCall
        checkLogin();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!orders) return
        setContextValue((ctx) => ({
            ...ctx,
            orders: orders
              .filter((o) => o.product.company === company)
              .map((o) => ({ ...o, product: formatProductObject(o.product) }))}))
    }, [orders]);

    useEffect(() => {
        if (!localToken || !contextValue.user) return
        mutate(`/order/filtered/${contextValue.user._id}`)
        // eslint-disable-next-line
    }, [localToken])

    return (<UserContext.Provider value={contextValue}>{children}</UserContext.Provider>);
};

export default UserProvider;
