import React from "react";

import styles from './loader.module.scss';

const Loader = ({ title, setDataLoaded = () => {} }) => {
    setTimeout(() => {
        setDataLoaded()
    }, 3000)
    return (
        <div className={styles.Loader}>
            { title ? <h2 className={styles.launch}>{title}<span className={styles.dots}>...</span></h2> : ""}
            <img src="/assets/flying-rocket.png" alt="Rocket" />
        </div>
    );
};

export default Loader;
