import React, { createContext, useContext, useEffect, useState } from 'react'
import useSWRImmutable from 'swr/immutable'
import Loader from '../Loader/Loader'
import { useRouter } from 'next/router'
import { formatProductObject, productTypes, bundlePlans } from '../../utils/utils'
import { toast } from 'react-toastify'

export const PillsInDailyTreatment = 31;
export const PaymentTaxes = 9.99;
export const DoctorConsultationFee = 0;

const defaultContextValue = {
  visitType: null,
  productType: null,
  allProducts: [],
  products: [],
  isPromotional: false,
  selectedDrug: null,
  selectedStrength: null,
  selectedPills: null,
  selectedProduct: null,
  recommendationProduct: null,
  setProductContextValue: () => {},
};

export const ProductContext = createContext(defaultContextValue);

function useProducts () {
  const { data, error } = useSWRImmutable("/product/allProducts")
  return {
    products: data,
    isLoading: !error && !data,
    isError: error
  }
}

export function useShippingMethods () {
  const { data, error } = useSWRImmutable("/order/shippingOptions")

  return {
    shippingMethods: data,
    isLoading: !error && !data,
    isError: error
  }
}

const ProductsContainer = ({ children }) => {
  const [contextValue, setContextValue] = useState(defaultContextValue);
  const { products, isLoading, isError, isPromotional } = useProducts();

  useEffect(() => {
      if (isLoading) return
      if (isError) {
        toast.error("Error loading products");
        return
      }
      setContextValue((ctx) => ({
        ...ctx,
        setProductContextValue: setContextValue,
        allProducts: [...products.map(formatProductObject), ...bundlePlans],
        isPromotional: localStorage.getItem('isPromotional')
      }));
  }, [products, isLoading, isError, isPromotional]);

  return (
      <ProductContext.Provider value={contextValue}>{children}</ProductContext.Provider>
  );
};

export const filterProducts = (allProducts, productType, productName = undefined) => {
  const visitType = productTypes[productType];

  let products = allProducts.filter((p) => p.type === visitType);
  if (productName)
    products = products.filter((p) => p.drug.toLowerCase() === productName.toLowerCase());

  let data = {visitType, productType, products}


  if (productName && products.length) {
    const strengthList = products
      .map((p) => p.strength)
      .filter((v, i, a) => a.indexOf(v) === i);
    const middleStrength = strengthList.length % 2 === 0 ? strengthList.length/2 - 1 : (strengthList.length-1) / 2;
    data = {
      ...data,
      selectedProduct: products[0],
      selectedDrug: products[0].drug,
      selectedStrength: strengthList[middleStrength],
    };
  }

  return data
}

export const ProductParseURLLayout = ({ children }) => {
  const router = useRouter();
  const { productType, productName } = router.query;
  const { productType: contextProductType, selectedDrug: contextProductName, allProducts, setProductContextValue } = useContext(ProductContext);

  const loadProducts = async () => {
    if (!allProducts.length) return;
    const data = filterProducts(allProducts, productType, productName)

    if (data.visitType === undefined) {
      await router.push("/");
      return
    }
    if (!data.products.length) {
      await router.push(`/products/${productType}`);
      return
    }

    setProductContextValue((ctx) => ({
      ...ctx,
      ...data
    }));
  }

  if (productType && allProducts.length && !contextProductType) {
    loadProducts();
  }

  useEffect(() => {
    if (productType !== contextProductType || productName !== contextProductName)
    loadProducts()
    // eslint-disable-next-line
  }, [productType, productName, contextProductName, contextProductType])

  return <div className="productContainer">{children}</div>
}

export default ProductsContainer;
